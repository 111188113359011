<template>
  <div class="home">
    <!-- <img src="../../assets/images/privacyPolicyTitle.png" alt="" /> -->
    <div class="bigTitle">用户协议</div>
    <div class="bigContent">发布日期：2021年11月27日</div>
    <div class="bigContent">生效日期：2020年10月27日</div>
    <div class="bigTitle">提示条款</div>
    <div class="bigContent">
      “{{appName}}”的运营主体{{companyName}}（以下简称“我们”,"本公司"）深知个人信息对您的重要性，并会尽力保护您的个人信息安全可靠，我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则，目的明确原则，选择同意原则，最少够用原则，确保安全原则，主体参与原则，公开透明原则等等。同时我们承诺，我们将按业界成熟的安全解决方案，采取相应的安全保护措施来保护您的个人信息。鉴此，我们制定本《用户协议》（下称“本协议”）并提醒您：
    </div>
    <div class="bigContent">本协议适用于“{{appName}}”软件及相关服务。</div>
    <div class="bigContent">
      在使用“{{appName}}”软件及相关服务前，请您务必仔细阅读并理解本协议，在确认充分理解并同意后使用相关产品或服务。一旦您使用“{{appName}}”软件及相关服务，即表示您已充分理解并同意本协议。如对本政策内容有任何疑问、意见或建议，您可通过“{{appName}}”提供的各种联系方式与我们联系。
    </div>
    <div class="bigContent">请您在使用本产品之前仔细阅读下列条款。您下载、安装或使用产本品或者单击“ 立即登录”表明您已经阅读本协议并充分理解、遵守本协议所有条款，包括涉及免除或者限制本公司责任的免责条款、用户权利限制条款、约定争议解决方式等，这些条款均用粗体字标注。如果您不同意本协议的全部或部分内容，请不要下载、安装和使用本产品。</div>

    <div class="bigTitle">1. 权利声明</div>
    <div class="bigContent">1.1 知识产权。</div>
    <div class="bigContent">本公司拥有“本产品”的所有权和知识产权等全部权利。本产品受中国及其他国家的知识产权法、国际知识产权公约（包括但不限于著作权法、商标法、专利法等）的保护。所有未授予您的权利均被本公司保留，您不可以从本产品上移除本公司的版权标记或其他权利声明。</div>
    <div class="bigContent">1.2 软件所有权保留。</div>
    <div class="bigContent">您确定不享有本软件的所有权，本软件未被出售给用户，本公司保留本软件的所有权。</div>
    <div class="bigTitle">2. 用户行为</div>
    <div class="bigContent">2.1在使用过程中，您将承担因下述行为而产生的全部法律责任，本公司不对您的下述行为承担任何责任：</div>
    <div class="bigContent">破坏宪法所确定的基本原则的；</div>
    <div class="bigContent">危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的；</div>
    <div class="bigContent">损害国家荣誉和利益的；</div>
    <div class="bigContent">煽动民族仇恨、民族歧视，破坏民族团结的；</div>
    <div class="bigContent">破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
    <div class="bigContent">散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
    <div class="bigContent">散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>
    <div class="bigContent">侮辱或者诽谤他人，侵害他人合法权益的；</div>
    <div class="bigContent">含有法律、行政法规禁止的其他内容的。</div>
    
    <div class="bigContent">2.2 您同意不通过本产品从事下列行为：</div>
    <div class="bigContent">发布或分享电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；</div>
    <div class="bigContent">未经授权，收集其他用户的信息或数据，例如非法收集第三人的个人信息侵犯第三人隐私或其他合法民事权益；</div>
    <div class="bigContent">用自动化的方式恶意使用本产品，给服务器造成过度的负担或以其他方式干扰或损害本产品服务器和网络链接；</div>
    <div class="bigContent">干扰、破坏本产品其他用户的使用；</div>
    <div class="bigContent">未经本公司授权，修改、破解、反编译、反汇编、逆向工程本产品，发布本产品的修改版、破解版等；</div>
    
    <div class="bigTitle">3. 功能的调整、改进与升级</div>
    <div class="bigContent">我们可能对产品进行不时地调整、改进和增减，甚至下线我们部分产品，以不断适应我们的运营需要。任何本产品的更新版本或未来版本或者其他变更同样受到本协议约束。</div>
    
    
    <div class="bigTitle">4.无担保声明</div>
    <div class="bigContent">4.1 本公司在发布本产品之前，已尽可能对产品进行了详尽的技术测试和功能测试，但鉴于电子设备、操作系统、网络环境的复杂性，本公司不能保证本产品会兼容所有用户的电子设备，也无法保证用户在使用本产品过程中能够持续不出现任何技术故障。</div>
    <div class="bigContent">4.2 在法律允许的最大限度内，本公司无法对产品或服务做任何明示、暗示和强制的担保，包括但不限于软件的兼容性；产品一定满足您的需求或期望；或产品将不间断的、及时的、安全的、或无错误的运行。</div>
    <div class="bigContent">4.3  由于网络环境的自由与开放特征，我们的产品或服可能会被第三方擅自修改、破解发布于互联网，建议用户从本公司的官方应用渠道，如官网、本公司已申请认证的第三方应用商店下载、安装我们的产品，我们不会对任何非官方版本承担任何责任。</div>
    
    <div class="bigTitle">5.不可抗力与责任限制</div>

    <div class="bigContent">不可抗力：本协议有效期间，如若遭受不可抗力事件，任何一方可暂行中止履行本协议项下的义务直至不可抗力事件的影响消除，并且遭受方无需为此承担违约责任，但应及时将不可抗力事件及时通知对方，并尽最大努力克服该事件，减少损失的扩大。不可抗力指各方不能控制、不可预见或即使预见亦无法避免的事件且该事件足以妨碍、影响或延误任何一方根据本协议履行其全部或部分义务。该事件包括但不限于自然灾害、战争、法律法规变更、政府命令、计算机病毒、黑客攻击或基础电信运营商服务中断等。</div>
    <div class="bigTitle">6.个人信息保护</div>
    <div class="bigContent">保护用户跟个人信息安全、维护用户隐私是我们一贯的理念，并且我们贯穿于所有产品或服务的立项、开发和运营过程。为不断优化用户体验，向用户提供更加个性化、智能化的内容与服务，我们会收集您的个人信息与非个人信息。您在使用特定的产品时，可以查看关于该产品的隐私说明以及我们的《隐私政策》，了解关于我们收集信息的内容、使用目的以及如何保护你的信息安全，该《隐私政策》构成本协议的一部分。</div>

    <div class="bigTitle">7.本协议的修改</div>
    <div class="bigContent">由于业务的拓展、调整或法规变化等原因，本公司可能会适时修改本协议至被法律所允许的程度。如果调整会对您的权利与义务造成重大影响，我们会尽可能通过电子邮件、应用内通知等方式告知您。我们建议您定期访查看我们的网站和移动应用程序，关注本协议的任何变化。在本协议修改后您继续使用本产品代表您接受修改后的协议内容。</div>


    <div class="bigContent">
      2）、与公共安全、公共卫生、重大公共利益有关的；
    </div>
    <div class="bigContent">
      3）、与犯罪侦查、起诉、审判和判决执行等有关的；
    </div>
    <div class="bigContent">
      4）、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的
    </div>
    <div class="bigContent">
      5）、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；
    </div>
    <div class="bigContent">6）、其他法律法规规定或司机机关要求的情形。</div>
    <div class="bigContent">
      根据《中华人民共和国网络安全法》等相关法律法规的规定，共享、披露经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、及披露行为，对此类数据的保存及处理将不再另行向您通知并征得您的同意。
    </div>

    <div class="bigTitle">四、我们如何保护您的个人信息</div>
    <div class="bigContent">
      （一）我们努力为用户的个人信息提供安全保障，以防止用户个人信息的非法使用、未经授权的访问和披露、泄漏、损坏和丢失。我们将在合理的安全水平内使用各种安全保护措施以保障信息的安全。例如，我们会使用加密技术（例如，SSL）、匿名化处理、加密存储等手段来保护你的个人信息；建立受信赖的保护机制防止个人信息遭到恶意攻击；建立访问控制机制，遵守最小权限原则，确保只有授权人员才可访问个人信息；开展安全和个人信息保护培训课程，加强员工对于保护个人信息保护的认识。
    </div>
    <div class="bigContent">
      （二）我们有行业先进的以数据为核心，围绕数据生命周期进行的数据安全管理体系，从管理机构、组织建设、制度体系、产品技术等方面多维度提升整个系统的安全性。我们建立专门的管理制度、流程和组织以保障个人信息的安全。例如，我们严格限制访问信息的人员范围，要求他们遵守保密义务，并进行审计。若发生个人信息泄露等安全事件，我们会启动应急预案，阻止安全事件扩大，并以推送通知、公告等形式告知您。
    </div>
    <div class="bigContent">
      （三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。
    </div>
    <div class="bigContent">
      如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络我们，以便我们根据您的申请采取相应措施
    </div>
    <div class="bigContent">
      请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。
    </div>
    <div class="bigContent">
      同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
    </div>

    <div class="bigTitle">五、您如何管理您的个人信息</div>
    <div class="bigContent">您可以通过以下方式访问及管理您的个人信息：</div>
    <div class="bigContent">（一）访问您的个人信息</div>
    <div class="bigContent">
      您有权访问您的个人信息，法律法规规定的例外情况除外。您可以通过以下方式自行访问您的个人信息：
    </div>
    <div class="bigContent">
      账户信息—如果您希望访问或编辑您的账户中的个人资料信息，如头像和昵称，您可以通过通过点击自己的头像-设置来执行此类操作。
    </div>
    <div class="bigContent">
      如果您无法通过上述路径访问该等个人信息，您可以随时通过“{{appName}}”客服与我们取得联系。我们将在15天内回复您的访问请求。
    </div>
    <div class="bigContent">
      对于您在使用我们的产品或服务过程中产生的其他个人信息，我们将根据本条“（五）响应您的上述请求”中的相关安排向您提供。
    </div>
    <div class="bigContent">（二）更正或补充您的个人信息</div>
    <div class="bigContent">
      当您发现我们处理的关于您的个人信息有错误时，您有权要求我们做出更正或补充。您可以通过本条“（一）访问您的个人信息”中列明的方式提出更正或补充申请。
    </div>
    <div class="bigContent">（三）删除您的个人信息</div>
    <div class="bigContent">
      您可以通过本条“（一）访问您的个人信息”中列明的方式删除您的部分个人信息。
    </div>
    <div class="bigContent">
      在以下情形中，您可以向我们提出删除个人信息的请求：
    </div>
    <div class="bigContent">1、如果我们处理个人信息的行为违反法律法规；</div>
    <div class="bigContent">
      2、如果我们收集、使用您的个人信息，却未征得您的明确同意；
    </div>
    <div class="bigContent">
      3、如果我们处理个人信息的行为严重违反了与您的约定；
    </div>
    <div class="bigContent">
      4、如果您不再使用我们的产品或服务，或您主动注销了账号；
    </div>
    <div class="bigContent">5、如果我们永久不再为您提供产品或服务。</div>
    <div class="bigContent">
      若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，要求其及时删除，除非法律法规另有规定，或这些主体获得您的独立授权。
    </div>
    <div class="bigContent">
      当您从我们的服务中删除信息后，我们可能不会立即从备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </div>
    <div class="bigContent">（四）改变您授权同意的范围</div>
    <div class="bigContent">
      每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一条”）。除此之外，对于额外个人信息的收集和使用，您可以在设置或与“{{appName}}”客服联系给予或收回您的授权同意。
    </div>
    <div class="bigContent">
      当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </div>
    <div class="bigContent">（五）响应您的上述请求</div>
    <div class="bigContent">
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。
    </div>
    <div class="bigContent">
      我们将在15天内做出答复。如您不满意，还可以通过“{{appName}}”客服发起投诉。
    </div>
    <div class="bigContent">
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，我们可能会予以拒绝。
    </div>
    <div class="bigContent">
      在以下情形中，按照法律法规要求，我们将无法同意您的请求：
    </div>
    <div class="bigContent">1、与国家安全、国防安全有关的；</div>
    <div class="bigContent">2、与公共安全、公共卫生、重大公共利益有关的；</div>
    <div class="bigContent">3、与犯罪侦查、起诉、审判和执行判决等有关的；</div>
    <div class="bigContent">
      4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；
    </div>
    <div class="bigContent">
      5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；
    </div>
    <div class="bigContent">6、涉及商业秘密的。</div>

    <div class="bigTitle">六、我们如何处理未成年人的个人信息</div>
    <div class="bigContent">
      1、我们特别重视未成年人的个人信息的保护。如果您为14周岁以下的未成年人，在使用我们的产品和/或服务前，请您务必在监护人的陪同下阅读本政策，并确保已征得您的监护人明确同意后，再使用我们的服务并向我们提供您的个人信息。
    </div>
    <div class="bigContent">
      2、对于经监护人同意使用我们的产品或服务而收集未成年人个人信息的情况，我们只会在法律法规允许、监护人明确同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。
    </div>
    <div class="bigContent">
      3、如果您的监护人不同意您按照本政策使用我们的服务或向我们提供个人信息，请您立即终止使用我们的服务并及时通知我们。
    </div>
    <div class="bigContent">
      4、如果您对您所监护的未成年人使用我们的产品和/或服务或其向我们提供的用户信息有任何疑问时，请您及时与我们联系。
    </div>

    <div class="bigTitle">七、我们存储您的个人信息的位置和方式</div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，存储在中国境内。
    </div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，以加密的方式存储。
    </div>
    <div class="bigContent">
      我们按本政策收集的您的个人信息，将在您删除或注销后立即删除或按相关法律法规的规定进行匿名化处理，但按相关法律法规或司法机关的要求需要延长保存时间的除外。在延长保存期间内，我们会以加密形式安全地存储您的个人信息并进行隔离，直至按相关法律法规或司法机关的要求可以删除或匿名化处理。
    </div>

    <div class="bigTitle">八、本隐私政策如何更新</div>
    <div class="bigContent">
      我们的隐私政策可能变更。未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。对于重大变更，我们会提供显著的通知（包括我们会通过“{{appName}}”公示的方式进行通知甚至向您提供弹窗提示）。
    </div>
    <div class="bigContent">本政策所指的重大变更包括但不限于：</div>
    <div class="bigContent">
      1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </div>
    <div class="bigContent">
      2、我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；
    </div>
    <div class="bigContent">
      3、个人信息共享、转让或公开披露的主要对象发生变化；
    </div>
    <div class="bigContent">
      4、您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </div>
    <div class="bigContent">
      5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </div>
    <div class="bigContent">6、个人信息安全影响评估报告表明存在高风险时。</div>

    <div class="bigTitle">九、如何联系我们</div>
    <div class="bigContent">
      您可以通过“{{appName}}”客服与我们联系，我们将在15天内回复您的请求。如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。
    </div>
    <div class="bigTitle">十、用户注销说明</div>
    <div class="bigContent">
      在您注销您的账户之前，请充分阅读、理解并同意下列事项：
    </div>
    <div class="bigContent">
      如需注销账户，请通过发送账户信息至邮件yanghejingxiang@126.com进行注销，我们会在收到您的邮件后的5个工作日内为您处理注销账户的事项。
    </div>
    <div class="bigContent">
      在此善意地提醒您，您注销账户的行为，将导致我们终止对您提供本服务，也会给您的售后维权带来诸多不便。注销成功后，我们将删除您的个人信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。
    </div>
    <div class="bigContent">
      1.如果您仍执意注销账户，您的账户需同时满足以下条件：
    </div>
    <div class="bigContent">
      （1）账户内无未完成的订单、已提供服务但未支付的订单/服务；
    </div>
    <div class="bigContent">
      （2）账户无任何纠纷，包括投诉举报或被投诉举报；
    </div>
    <div class="bigContent">
      （3）账户为正常使用中的账户且无任何账户被限制的记录；
    </div>
    <div class="bigContent">
      2.账户一旦被注销将不可恢复，请您在操作之前自行备份账户相关的所有信息和数据。注销账户，您将无法再使用本账户，也将无法找回您账户中及与账户相关的任何内容或信息（即使您使用相同的手机号码再次注册并使用），包括但不限于：
    </div>
    <div class="bigContent">（1）您将无法登录、使用账户；</div>
    <div class="bigContent">
      （2）账户的个人资料和历史信息（包括但不限于用户名、昵称、头像、记录等）都将无法找回；
    </div>
    <div class="bigContent">
      3.注销账户并不代表账户注销前的账户行为和相关责任得到豁免或减轻。
    </div>

    <!-- <div class="bigContent"></div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      // appName: "仰和安心",
      // companyName: "深圳市仰和镜象技术有限公司",
      appName: "",
      companyName: "",
    };
  },
  created () {
    this.appName = this.$route.query.aName
    this.companyName = this.$route.query.cName
  }
};
</script>
<style lang="scss" scoped>
.home{
  box-sizing: border-box;
  padding: .3rem;
  background-color: #f7f7f7; 
  img{
    width: 1.28rem;
    height: .36rem;
  }
  .bigTitle{
    font-size: .28rem;
    color: #333;
    font-weight: bold;
    padding: .3rem 0 0;
  }
  .bigContent{
    font-size: .28rem;
      color: #4d4d4d;
      padding: .2rem 0 0;
      line-height: .4rem;
  }
}
</style>
